import { usePaystackPayment } from 'react-paystack';
import { Platformbutton } from '../common/buttons';
import axios from "axios";
import { setLocalStorage } from '../../helpers/utils';




export const PaymentBtn = (props) => {

    const config = {
        email: props.data.email,
        amount: props.data.amount * 100,
        publicKey: "pk_test_cca041ac7d6ef82781ca596423142c9db40d7e2e",
        //publicKey: "pk_live_1525008a6a50293abad6e8f3d380b41e9a0f452e",
    };

  

    // you can call this function anything
    const onSuccess = (res) => {
        console.log(res.reference);
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://api.paystack.co/transaction/verify/'+res.reference,
        headers: { 
          //'Authorization': 'Bearer sk_live_032becf8bf31ab8697ccfa577793ef73e6c4b705',
          'Authorization': 'Bearer sk_test_dc01782d79b533264cd62b3cc012bc34b0045649',
          "Cache-Control": "no-cache",
          
        }
    };
    
    axios.request(config)
    .then((response) => {
 
      var paymentVerify = response.data.data;

      setLocalStorage('paymentVerify',paymentVerify);

      //console.log("refere set, about to topup")
      props.click(res.reference)

    })
    .catch((error) => {
      //console.log(error);
    });

   };

    // you can call this function anything
    const onClose = () => {
    }
    const initializePayment = usePaystackPayment(config);
    return (
        <Platformbutton type="normal" name={props.loading ? "Finalizing...": "Make Payment"} loading={props.loading} click={()=> initializePayment(onSuccess, onClose)} disabled={props.disabled} />
    // <div>

    //     <button onClick={() => {
    //         initializePayment(onSuccess, onClose)
    //     }}>Paystack Hooks Implementation</button>
    // </div>
    );
};